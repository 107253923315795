import type { CartPageMicroFrontendInterface } from '~/composables/types/service/cartPageMicroFrontendInterface'

export const ccMicroFrontend = (): CartPageMicroFrontendInterface => {
    const { $cookies } = useNuxtApp()
    const { data } = useAuth()
    const getAccessToken = (): string => {
        if (data.value !== null) {
            return data.value?.token ?? ''
        }
        return $cookies.getRefs(CookieNameTypes.TOKEN_KEY)?.replace('Bearer ', '') ?? ''
    }

    return {
        getAccessToken,
    }
}
